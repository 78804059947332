export const REQUESTED_OC_FREEMIUM_KEY = 'REQUESTED_OC_FREE';
export const REQUESTED_OC_VAL = 'yes';
export const REQUESTED_DEMO_KEY = 'REQUESTED_DEMO';
export const REQUESTED_DEMO_VAL = 'yes';
export const LAST_DEMO_REQUEST_TIMESTAMP_KEY = 'LAST_DEMO_REQUEST_TIMESTAMP';
export const POST_CONTENT_KEY = 'post_content_key';
export const ORG_ID_KEY = 'ORG_ID';
export const COMPANY_KEY = 'COMPANY';
export const FISCAL_YEAR_START_MONTH_KEY = 'FISCAL_YEAR_START_MONTH';
export const COMMUNITY_ONBOARDING_CHECKLIST_KEY = 'COMMUNITY_ONBOARDING_CHECKLIST_KEY';
export const IS_MORE_RATED_POSTS_PRESENT = 'IS_MORE_RATED_POSTS_PRESENT';
export const GENIE_2_ONBOARDING_GETTING_STARTED_UNFAVORITED = 'GENIE_2_ONBOARDING_GETTING_STARTED_UNFAVORITED';
export const GENIE_2_ONBOARDING_TIPS_TRICKS_UNFAVORITED = 'GENIE_2_ONBOARDING_TIPS_TRICKS_UNFAVORITED';
export const GENIE_ONBOARDING_CHECKLIST_USE_PRE_BUILT_PROMPT_KEY =
  'GENIE_ONBOARDING_CHECKLIST_USE_PRE_BUILT_PROMPT_DONE';
export const GENIE_ONBOARDING_CHECKLIST_SELECT_PERSONALITY_KEY = 'GENIE_ONBOARDING_CHECKLIST_SELECT_PERSONALITY_DONE';
export const GENIE_ONBOARDING_CHECKLIST_ASK_A_QUESTION_KEY = 'GENIE_ONBOARDING_CHECKLIST_ASK_A_QUESTION_DONE';
export const GENIE_2_SEEN_WELCOME_SPLASH_SCREEN_SEEN = 'GENIE_2_SEEN_WELCOME_SPLASH_SCREEN_SEEN';
export const GENIE_2_ONBOARDING_GUIDE_SEEN = 'GENIE_2_ONBOARDING_GUIDE_SEEN';
export const GENIE_2_GETTING_STARTED_ONBOARDING_SEEN = 'GENIE_2_GETTING_STARTED_ONBOARDING_SEEN';
export const GENIE_2_TIPS_AND_TRICKS_ONBOARDING_SEEN = 'GENIE_2_TIPS_AND_TRICKS_ONBOARDING_SEEN';
export const GENIE_2_GETTING_STARTED_ONBOARDING_FEEDBACK = 'GENIE_2_GETTING_STARTED_ONBOARDING_FEEDBACK';
export const CPOHQ_BENCHMARKING_SEP_2023_DONE = 'CPOHQ_BENCHMARKING_SEP_2023_DONE';
export const LAST_CPOHQ_EVENT_FEEDBACK_DEMO_SEEN_TIMESTAMP_KEY = 'LAST_CPOHQ_EVENT_FEEDBACK_DEMO_SEEN_TIMESTAMP';
export const POSTID_KEY = 'POSTID';
export const REPLY_CONTENT_KEY = `REPLY_TO_${POSTID_KEY}_CONTENT`; // POSTID key has dynamic value
export const CURRENCY_CONVERSION_SETTINGS_SEEN = 'CURRENCY_CONVERSION_SETTINGS_SEEN';
// Recruiting Pipeline Page
export const FIRST_TIME_BREAKDOWN_SELECTED = 'FIRST_TIME_BREAKDOWN_SELECTED';
