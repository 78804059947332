import React, { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import FullPageLoader from 'components/FullPageLoader';

import { ORG_ID_KEY } from 'constants/storageKeyVal';

import UserProvider from 'context/UserProvider';

import httpClient from 'api/base';
import { API_ROOT } from 'config';

import 'styles/tailwind.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';
import 'styles/index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PublicApp = React.lazy(() => import('./PublicApp'));
const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const { isLoading, error, isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();

  // Assume that the user has a previously active session.
  const [isPrevAuthed, setIsPrevAuthed] = useState(true);

  useEffect(() => {
    const checkPrevAuth = async () => {
      try {
        // Auth0 will be able to get a token silently even though the users has cleared cookies.
        const token = await getAccessTokenSilently({
          authorizationParams: {
            redirect_uri: window.location.origin,
            audience: API_ROOT,
            organization: localStorage.getItem(ORG_ID_KEY),
          },
        });
        // If it is not an empty string, means the previous session is still available.
        setIsPrevAuthed(!!token);
      } catch (err) {
        // If auth0 is not logged in, isPrevAuthed will become false and users will be redirected to the login page
        setIsPrevAuthed(false);
      }
    };

    checkPrevAuth();
  }, [getAccessTokenSilently]);

  // TODO: Consider refactoring httpClient - use an Auth0 global singleton instead
  // Require to allow our API layer to retrieve accessToken and auth0 logout method
  useEffect(() => {
    httpClient.setTokenGenerator(getAccessTokenSilently);
    httpClient.setLogoutMethod(logout);
  }, [getAccessTokenSilently, logout, isAuthenticated]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
      setTimeout(() => logout(), 3000);
    }
  }, [error, logout]);

  if (error) return <FullPageLoader loadingText="An error has occured. Logging out..." />;
  if (isLoading) return <FullPageLoader loadingText="Checking session data..." />;
  if (isPrevAuthed && !user) return <FullPageLoader loadingText="Checking previous session..." />;

  let content = null;
  if (!isPrevAuthed && !isAuthenticated) {
    content = <PublicApp />;
  } else {
    content = (
      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <AuthenticatedApp />
        </QueryClientProvider>
      </UserProvider>
    );
  }

  return <React.Suspense fallback={<FullPageLoader loadingText="Loading..." />}>{content}</React.Suspense>;
};

export default App;
