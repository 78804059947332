import { FullStory, init, isInitialized } from '@fullstory/browser';

import { getEnvironment, environments, FULL_STORY_PROPERTIES } from 'config';

const shouldTrack = (): boolean => {
  // Should track only on staging & production environments
  return getEnvironment() === environments.PRODUCTION;
};

const initFullStory = (): void => {
  init(
    {
      ...FULL_STORY_PROPERTIES,
      devMode: !shouldTrack(),
    },
    () => {
      if (!shouldTrack()) {
        return;
      }

      console.log('Fullstory initialized');
    },
  );
};

const initFullStoryUserIdentification = (uid: string, user: { name: string; email: string }) => {
  FullStory('setIdentity', {
    uid,
    properties: {
      displayName: user.name,
      email: user.email,
    },
  });
};

const trackEvent = (name: string, properties: Record<string, string>): void => {
  if (!isInitialized()) {
    return;
  }

  if (!shouldTrack()) {
    console.log('FullStory track', name, properties);
  }

  FullStory('trackEvent', { name, properties });
};

export { initFullStory, initFullStoryUserIdentification, trackEvent };
